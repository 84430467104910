import { mapActions } from 'vuex'

export default {
  data() {
    return {
      selectedItems: [],
      checkAll: false,
      isIndeterminate: true,
      mainDistrict: {
        title: '',
        id: null
      }
    }
  },
  computed: {
    name() {
      return this.district?.title
    }
  },
  mounted() {
    this.setMainDistrict()
    this.highlightSaved()
    this.$bus.$on('sentDistricts', () => this.handleSentDistricts())
    this.$bus.$on('resetDistricts', () => this.resetDistricts())
  },
  beforeDestroy() {
    this.$bus.$off('chooseDistricts')
    this.$bus.$off('resetDistricts')

    this.selectedItems = []
  },
  methods: {
    ...mapActions({
      clearDistricts: 'search/CLEAR_DISTRICTS'
    }),
    setMainDistrict() {
      const { title, id } = this.district
      this.mainDistrict.title = title
      this.mainDistrict.id = id
    },
    handleCheckAll(val) {
      this.selectedItems = val ? this.district.micro_districts : []
      this.isIndeterminate = !this.isIndeterminate
    },
    handleCheckArea(val) {
      const checkedCount = val.length
      this.checkAll = checkedCount === this.district.micro_districts.length
      this.isIndeterminate = checkedCount
        ? checkedCount < this.district.micro_districts.length
        : false
    },
    handleSentDistricts() {
      this.checkAll
        ? this.$emit('chooseDistrict', this.mainDistrict)
        : this.$emit('chooseMicroDistricts', this.selectedItems)
    },
    async resetDistricts() {
      this.selectedItems = []
      this.checkAll = false
      await this.clearDistricts()
    },
    highlightSaved() {
      if (this.savedDistr.includes(this.district?.id)) {
        this.isIndeterminate = true
        this.checkAll = true
        this.selectedItems = this.district?.micro_districts
      } else {
        this.selectedItems = this.district?.micro_districts.filter(
          (microdistr) => {
            return this.savedMicroDistr.includes(microdistr.id)
          }
        )
      }
    }
  }
}
