<template>
  <div class="buttons-block">
    <button class="select" @click="select">
      {{ $t('common.choose') }}
    </button>
    <button class="reset" @click="reset">
      {{ $t('common.reset') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonsBlock',
  props: {
    select: {
      type: Function,
      default: () => 1
    },
    reset: {
      type: Function,
      default: () => 1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/css/variables';

.buttons-block {
  width: 100%;
  padding: 0 10px;
  position: fixed;
  left: 0;
  bottom: 18px;
  z-index: 10000;
  background-color: white;

  button {
    height: 40px;
    line-height: 40px;
    width: 100%;
    opacity: 0.9;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.18);
    font-size: 16px;

    &.select {
      background-color: $accent;
      color: #fff;
      margin-bottom: 10px;
    }

    &.reset {
      background-color: #fff;
      color: $gray;
    }
  }
}

@media (min-width: 768px) {
  .buttons-block {
    position: static;
    display: flex;
    justify-content: center;

    button {
      font-size: 18px;

      &.select {
        order: 2;
        max-width: 200px;
        opacity: 1;
        transition: 0.3s ease;

        &:hover {
          opacity: 0.9;
          transition: 0.3s ease;
        }
      }
      &.reset {
        order: 1;
        max-width: 150px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 992px) {
  .dropdown-choose-location {
    .buttons-block {
      width: 100%;
      padding: 0 10px;
      position: fixed;
      left: 0;
      bottom: 18px;
      z-index: 10000;
    }
  }
}
</style>
