<template>
  <div class="stations">
    <el-checkbox-group
      v-for="line in shownLines"
      :key="line"
      v-model="selected"
      text-color="#fff"
      :fill="color(line)"
      class="mobile mb-4"
    >
      <el-checkbox-button
        v-for="el in stations[line]"
        :key="el.id"
        :label="el.id"
      >
        <span class="circle" :style="{ borderColor: color(line) }">
          <span class="line bottom" />
          <span class="line top" />
        </span>
        {{ el.title }}
      </el-checkbox-button>
    </el-checkbox-group>
    <div v-if="map" class="subway-map" :class="map.class">
      <img class="map-image" :src="map.url" alt="subway-map" />
      <template v-for="(_, _idx) in stations">
        <el-checkbox-group
          :key="_idx"
          v-model="selected"
          :fill="getLineColor(_idx)"
          text-color="#fff"
        >
          <el-checkbox-button
            v-for="stat in stations[_idx]"
            :key="stat.id"
            :label="stat.id"
          >
            {{ stat.title }}
          </el-checkbox-button>
        </el-checkbox-group>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SubwayStationsItem',
  props: {
    stations: {
      type: Object,
      default: () => {}
    },
    tab: {
      type: Number,
      default: 0
    },
    map: {
      type: Object,
      default: () => {}
    },
    lines: {
      type: Object,
      default: () => {}
    },
    shownLines: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    ...mapGetters({
      subwayLines: 'search/getSubwayLinesMap',
      searchSubway: 'search/searchSubway'
    })
  },
  watch: {
    searchSubway() {
      this.selected = this.searchSubway
    }
  },
  mounted() {
    if (this.searchSubway) {
      this.selected = this.searchSubway
    }

    this.$bus.$on('chooseSubway', async () => {
      await this.setSubway(this.selected)
    })
    this.$bus.$on('resetSubway', async () => {
      await this.setSubway([])
    })
  },
  beforeDestroy() {
    this.$bus.$off('chooseSubway')
    this.$bus.$off('resetSubway')
  },
  methods: {
    ...mapActions({
      setSubway: 'search/SET_SUBWAY'
    }),
    color(_i) {
      //  eslint-disable-next-line camelcase
      return `#${this.lines[_i]?.color_rgb}`
    },
    getLineColor(_idx) {
      // eslint-disable-next-line camelcase
      return `#${this.subwayLines[_idx]?.color_rgb}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/css/subway-maps-coords';
</style>
