<template>
  <div class="district">
    <el-checkbox
      v-model="checkAll"
      class="distr-name"
      :indeterminate="isIndeterminate"
      @change="handleCheckAll"
    >
      <span>{{ name }}</span>
    </el-checkbox>
    <el-checkbox-group
      v-model="selectedItems"
      class="areas-block"
      @change="handleCheckArea"
    >
      <el-checkbox
        v-for="(area, idx) in district.micro_districts"
        :key="idx"
        :label="area"
      >
        {{ area.title }}
        <i class="el-icon-arrow-right" />
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import SelectDistrictMixin from '@/dependencies/mixins/select-district'
export default {
  name: 'DistrictItem',
  mixins: [SelectDistrictMixin],
  props: {
    district: {
      type: Object,
      default: () => {}
    },
    savedMicroDistr: {
      type: Array,
      default: () => []
    },
    savedDistr: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped></style>
