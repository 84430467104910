<template>
  <div>
    <div v-if="subwayLines" class="subway-block">
      <div class="tabs">
        <button
          v-for="line in subwayLines"
          :key="line.id"
          :style="
            tab === line.id ? activeClass(line.id) : dynamicStyles(line.id)
          "
          @click="tab = line.id"
        >
          {{ line.title }}
        </button>
      </div>
      <SubwayStationsItem
        v-if="subwayStations"
        :stations="subwayStations"
        :lines="subwayLinesMap"
        :tab="tab"
        :map="subwayMap"
        :shown-lines="[tab]"
      />
      <div v-else class="loader-wrapper">
        <vue-loaders name="ball-grid-pulse" :color="primaryColor" />
      </div>
    </div>
    <div v-else class="loader-wrapper">
      <vue-loaders name="ball-grid-pulse" :color="primaryColor" />
    </div>
    <ButtonsBlock :select="selectHandler" :reset="resetHandler" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SubwayStationsItem from '@/components/Dropdowns/Parts/SubwayStationsItem'
import ButtonsBlock from '@/components/Dropdowns/Parts/ButtonsBlock'
import constants from '@/dependencies/constants'

export default {
  name: 'SubwayStations',
  components: {
    SubwayStationsItem,
    ButtonsBlock
  },
  data() {
    return {
      selected: [],
      tab: undefined
    }
  },
  computed: {
    ...mapGetters({
      subwayLines: 'search/getSubwayLines',
      subwayStations: 'search/getSubwayStations',
      getSubwayMap: 'search/getSubwayMap',
      subwayLinesMap: 'search/getSubwayLinesMap',
      isMobile: 'common/getWidth'
    }),
    subwayMap() {
      if (!this.isMobile) return this.getSubwayMap
      return null
    },
    primaryColor() {
      return constants.COLORS.PRIMARY_COLOR
    }
  },
  async mounted() {
    if (!this.subwayLines || !this.subwayStations) {
      await this.loadSubwayLines()
    }
    this.tab = this.subwayLines[0].id
  },
  methods: {
    ...mapActions({
      loadSubwayLines: 'search/LOAD_SUBWAY_LINES'
    }),
    dynamicStyles(_idx) {
      const color = this.subwayLinesMap[_idx]?.color_rgb
      return {
        borderColor: `#${color}`,
        color: `#${color}`
      }
    },
    activeClass(_idx) {
      return {
        backgroundColor: `#${this.subwayLinesMap[_idx]?.color_rgb}`,
        color: '#fff',
        border: 'none'
      }
    },
    selectHandler() {
      this.$emit('clicked')
      this.$bus.$emit('chooseSubway')
    },
    resetHandler() {
      this.$bus.$emit('resetSubway')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/css/variables';
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 70vh;
}
.subway-block {
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    button {
      border-radius: 3px;
      font-size: 18px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      width: 34%;
      border: 1px solid;
      margin-right: 10px;
      transition: 0.3s background-color linear;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .subway-block {
    .tabs {
      display: none;
    }
  }
}
</style>
