import { mapActions } from 'vuex'
import constants from '@/dependencies/constants'

export default {
  data() {
    return {
      window: undefined,
      mobile: false
    }
  },
  beforeMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapActions({
      setMobile: 'common/SET_WIDTH'
    }),
    async handleResize() {
      if (window.innerWidth < constants.COMMON.MOBILE_SIZE && !this.mobile) {
        this.mobile = !this.mobile
        await this.setMobile(this.mobile)
      } else if (
        window.innerWidth >= constants.COMMON.MOBILE_SIZE &&
        this.mobile
      ) {
        this.mobile = !this.mobile
        await this.setMobile(this.mobile)
      }
    }
  }
}
