export default {
  computed: {
    microDistrArr() {
      return this.savedMicroDistricts?.map(({ id }) => id)
    },
    distrArr() {
      return this.savedDistricts.map(({ id }) => id)
    }
  }
}
