<template>
  <div class="districts-block">
    <template v-for="(district, idx) in districts">
      <DistrictItem
        :key="idx"
        :district="district"
        :saved-micro-distr="microDistrArr"
        :saved-distr="distrArr"
        @chooseDistrict="handleDistrict"
        @chooseMicroDistricts="handleMicroDistricts"
      />
    </template>
    <ButtonsBlock :select="selectHandler" :reset="resetHandler" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DistrictItem from '@/components/Dropdowns/Parts/DistrictItem'
import ButtonsBlock from '@/components/Dropdowns/Parts/ButtonsBlock'
import ExtractDistrictsID from '@/dependencies/mixins/extract-districts-id'

export default {
  name: 'Districts',
  components: {
    DistrictItem,
    ButtonsBlock
  },
  mixins: [ExtractDistrictsID],
  data: () => ({
    selectedDistricts: [],
    selectedMicroDistricts: []
  }),
  computed: {
    ...mapGetters({
      districts: 'search/getCityDistricts',
      savedDistricts: 'search/searchDistrict',
      savedMicroDistricts: 'search/searchMicroDistrict'
    })
  },
  methods: {
    ...mapActions({
      setDistrict: 'search/SET_DISTRICT',
      setMicroDistrict: 'search/SET_MICRO_DISTRICT',
      setAllDistricts: 'search/SET_ALL_DISTRICTS'
    }),
    async selectHandler() {
      this.$bus.$emit('sentDistricts')
      this.$emit('clicked')

      await this.setAllDistricts({
        districts: this.selectedDistricts,
        microdistricts: this.selectedMicroDistricts
      })

      this.selectedDistricts = []
      this.selectedMicroDistricts = []
    },
    async resetHandler() {
      this.$bus.$emit('resetDistricts')
      await this.setAllDistricts({
        districts: [],
        microdistricts: []
      })
    },
    handleDistrict(district) {
      this.selectedDistricts = [...this.selectedDistricts, district]
    },
    handleMicroDistricts(microdistricts) {
      this.selectedMicroDistricts = [
        ...this.selectedMicroDistricts,
        ...microdistricts
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.districts-block {
  height: 85vh;
  overflow-y: scroll;
  padding-bottom: 100px;
}

@media (min-width: 992px) {
  .districts-block {
    height: auto;
    overflow: unset;
    padding-bottom: 0;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;

    .district {
      width: 25%;
    }
  }
}
</style>
