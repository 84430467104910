// Getters for "title" and "content" must be named as in this Mixin

export default {
  head() {
    return {
      title: this.metaTitle,
      meta: [
        {
          hid: this.$uuid.v4(),
          name: 'description',
          content: this.metaContent
        }
      ]
    }
  }
}
