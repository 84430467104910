import { mapActions, mapGetters } from 'vuex'
import constants from '@/dependencies/constants'

export default {
  data() {
    return {
      CACHED_PATHS: [
        'location-search',
        'location-trainer-id',
        'location-club-id'
      ]
    }
  },
  computed: {
    ...mapGetters({
      club: 'search/searchSportClub',
      sport: 'search/searchSportType'
    }),
    getUrl() {
      return this.club || this.sport
        ? `${(this.club || this.sport).type}/${(this.club || this.sport).url}`
        : ''
    }
  },
  methods: {
    ...mapActions({
      setSportType: 'search/SET_SPORT_TYPE',
      setSportClub: 'search/SET_SPORT_CLUB',
      setCurrentPage: 'search/SET_CURRENT_PAGE'
    }),
    async handleSelection() {
      if (!this.chosenItem) return
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      switch (this.chosenItem.type) {
        case constants.COMMON.SPORT_PROPERTY:
          await this.setSportType(this.chosenItem)
          break
        case constants.COMMON.CLUB_PROPERTY:
          await this.setSportClub(this.chosenItem)
          break
        default:
          // this will clear both sportClub and sportType
          await this.setSportClub(null)
      }
    }
  }
}
